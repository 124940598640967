import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout/layout';
import { PageHeading } from '../components/layout/page-heading';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const mapEventColour = (color) => {
  switch (color) {
    case 'Blue':
      return {
        bg: '#e2f7ff',
        icon: '#92c8db',
        link: 'text-blueDark',
      };
    case 'Pink':
      return {
        bg: '#f2dee7',
        icon: '#edbbd1',
        link: 'text-pinkDark',
      };
    case 'Purple':
      return {
        bg: '#E0DCEB',
        icon: '#c0ADC5',
        link: 'text-purpleDark',
      };
    case 'Green':
      return {
        bg: '#d3ede3',
        icon: '#8fd6bc',
        link: 'text-greenDark',
      };
    case 'Orange':
    default:
      return {
        bg: '#fdf7e9',
        icon: '#eac677',
        link: 'text-secondary',
      };
  }
};

const Calendar = ({ data }) => {
  const { title, events } = data.contentfulCalendarPage;

  const today = new Date();

  const sortedEvents = events
    .filter((event) => event.title !== 'DUMMY CONTENT - (DO NOT DELETE)')
    .filter((event) => today <= new Date(event.rawDate))
    .map((event) => {
      return {
        ...event,
        color: mapEventColour(event.eventColour),
      };
    })
    .sort((a, b) => {
      return new Date(a.rawDate) - new Date(b.rawDate);
    });

  return (
    <Layout title={title}>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
          <div className="max-w-2xl mx-auto py-8 lg:max-w-none">
            <PageHeading title={title} styles={'md:text-center'} />
            <div className="mt-8">
              <VerticalTimeline lineColor="#FFCC53">
                {sortedEvents.map((event) => (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work rounded-lg"
                    contentStyle={{
                      background: `${event.color.bg}`,
                      borderBottomLeftRadius: '0.5rem',
                      borderBottomRightRadius: '0.5rem',
                    }}
                    contentArrowStyle={{
                      borderRight: `7px solid ${event.color.bg}`,
                    }}
                    date={event.eventDate}
                    iconStyle={{
                      background: `${event.color.icon}`,
                      color: '#fff',
                    }}
                    key={event.title}
                  >
                    <h3 className="font-heading font-semibold text-gray-900 text-2xl mb-2">
                      {event.title}
                    </h3>
                    <h4 className="text-md font-semibold text-gray-500">
                      {event.formattedDateTime}
                    </h4>
                    {event.description.description && (
                      <p className="text-lg font-medium text-gray-700">
                        {event.description.description}
                      </p>
                    )}
                    {event.externalEventLinkUrl && (
                      <a
                        href={event.externalEventLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${event.color.link} block font-semibold hover:underline visited:text-purple-600 mt-2`}
                      >
                        Event Link
                      </a>
                    )}
                  </VerticalTimelineElement>
                ))}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Calendar;

export const query = graphql`
  query {
    contentfulCalendarPage {
      title
      events {
        title
        eventDate(formatString: "dddd, MMMM Do YYYY")
        formattedDateTime: eventDate(formatString: "LLLL")
        rawDate: eventDate
        eventColour
        externalEventLinkUrl
        description {
          description
        }
      }
    }
  }
`;
